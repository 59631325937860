import { Action, Module } from 'vuex-module-decorators'
import { User } from '@/entity'
import { AuthenticationService } from '@/service'
import { getToken, removeToken, setToken } from '@/common/auth-token'
import { BehaviorSubject } from 'rxjs'
import { BaseStore } from '@/store/BaseStore'

@Module({
  name: 'auth',
  namespaced: true
})
export class AuthStore extends BaseStore<any> {
  private authService: AuthenticationService = new AuthenticationService()
  currentUserSubject = new BehaviorSubject(getToken())

  get currentUser() {
    return this.currentUserSubject.asObservable()
  }

  @Action({ rawError: true })
  loginAction(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      this.context
        .dispatch('execute', this.authService.login(user))
        .then((data) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          setToken(data)
          this.currentUserSubject.next(data)
          this.context.commit('setRootUser', data, { root: true })
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  @Action({ rawError: true })
  logoutAction() {
    return new Promise((resolve) => {
      removeToken()
      this.currentUserSubject.next(null)
      this.context.commit('logout', null, { root: true })
      resolve('ok')
    })
  }

  @Action({ rawError: true })
  registerAction(model: User) {
    return this.context.dispatch('execute', this.authService.register(model))
  }
}
