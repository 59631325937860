import { Observable } from 'rxjs'
import { ClientRepository } from '@/data/repository'
import { UserLayer, ClientMessage, ClientCount, LayerFromTag } from '@/entity'
export class ClientService {
  private clientRepo: ClientRepository = new ClientRepository()

  sendClientMessage(userLayer: UserLayer): Observable<ClientMessage> {
    return this.clientRepo.sendMessage(userLayer)
  }

  countClientAction(countType: ClientCount): Observable<ClientCount> {
    return this.clientRepo.countClient(countType)
  }

  getLayerFromTag(tagID: string): Observable<LayerFromTag> {
    return this.clientRepo.getLayerFromTag(tagID)
  }
}
