import { Observable } from 'rxjs'
import { CreateTag, Tag, TagList } from '@/entity/tag'
import { TagRepository } from '@/data/repository/tag-repository'

export class TagService {
  private tagRepo: TagRepository = new TagRepository()

  getTagList(searchParams): Observable<TagList> {
    return this.tagRepo.getAll(searchParams)
  }

  createNewTag(tag: CreateTag): Observable<any> {
    return this.tagRepo.create(tag)
  }

  getTagDetail(tagId: String): Observable<Tag> {
    return this.tagRepo.getDetail(tagId)
  }
  deleteTag(tagId: String): Observable<any> {
    return this.tagRepo.deleteTag(tagId)
  }

  updateTag(object): Observable<CreateTag> {
    return this.tagRepo.updateTag(object)
  }
}
