import { Observable } from 'rxjs'
import { QaRepository } from '@/data/repository'
import {
  CreateQa,
  Qa,
  QaList,
  QaBreadcrumbList,
  UpdateQa,
  ReorderQaList,
} from '@/entity/qa'

export class QaService {
  private qaRepo: QaRepository = new QaRepository()

  getQaList(searchParams): Observable<QaList> {
    return this.qaRepo.getAll(searchParams)
  }

  getQaDetail(qaId: string): Observable<Qa> {
    return this.qaRepo.getDetail(qaId)
  }

  deleteQa(qaId: string): Observable<any> {
    return this.qaRepo.deleteQa(qaId)
  }

  createQa(object: any): Observable<CreateQa> {
    return this.qaRepo.createQa(object)
  }

  getBreadcrumb(qaId: string): Observable<QaBreadcrumbList> {
    return this.qaRepo.getBreadcrumb(qaId)
  }

  updateQa(newQa: UpdateQa): Observable<UpdateQa> {
    return this.qaRepo.updateQa(newQa)
  }

  reorderQaList(newQaList: ReorderQaList): Observable<ReorderQaList> {
    return this.qaRepo.reorderQaList(newQaList)
  }
}
