import initializeAxios from './config'
import { catchError, map } from 'rxjs/operators'
import { defer, Observable } from 'rxjs'

const axiosInstance = initializeAxios()

const get = <T>(url: string, queryParams?: object): Observable<T> => {
  return defer(() => axiosInstance.get<T>(url, { params: queryParams })).pipe(
    map((result) => result.data),
    catchError((err) => {
      throw err
    })
  )
}

const post = <T>(
  url: string,
  body: object,
  queryParams?: object
): Observable<T | void> => {
  return defer(() =>
    axiosInstance.post<T>(url, body, { params: queryParams })
  ).pipe(
    map((result) => result.data),
    catchError((err) => {
      throw err
    })
  )
}

const put = <T>(
  url: string,
  body: object,
  queryParams?: object
): Observable<T | void> => {
  return defer(() =>
    axiosInstance.put<T>(url, body, { params: queryParams })
  ).pipe(
    map((result) => result.data),
    catchError((err) => {
      throw err
    })
  )
}

const patch = <T>(
  url: string,
  body: object,
  queryParams?: object
): Observable<T | void> => {
  return defer(() =>
    axiosInstance.patch<T>(url, body, { params: queryParams })
  ).pipe(
    map((result) => result.data),
    catchError((err) => {
      throw err
    })
  )
}

const deleteR = <T>(url: string, body: object): Observable<T | void> => {
  return defer(() => axiosInstance.delete<T>(url, { data: body })).pipe(
    map((result) => result.data),
    catchError((err) => {
      throw err
    })
  )
}

export default { get, post, put, patch, delete: deleteR }
