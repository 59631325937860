import { UserSettings } from '@/entity'
import { Observable } from 'rxjs'
import { UserRepository } from '@/data'

export class UserService {
  private userRepo: UserRepository = new UserRepository()

  getUserSettings(userId?: string): Observable<UserSettings> {
    return this.userRepo.getUserSettings(userId)
  }

  putUserSettings(model): Observable<any> {
    return this.userRepo.putUserSettings(model)
  }
}
