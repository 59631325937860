import { Observable } from 'rxjs'
import { InsightRepository } from '@/data/repository'
import { InsightDetail, InsightOverview } from '@/entity/insight'

export class InsightService {
  private insightRepo: InsightRepository = new InsightRepository()

  getInsightDetail(obj: {
    deep: number
    tag?: string
    layer?: string
    from: string
    to: string
  }): Observable<InsightDetail> {
    return this.insightRepo.detailInsight(obj)
  }

  getInsightOverview(obj: {
    compare_from: string
    compare_to: string
    from: string
    to: string
  }): Observable<InsightOverview> {
    return this.insightRepo.overviewInsight(obj)
  }

  getInsightTag(obj) {
    return this.insightRepo.getInsightTag(obj)
  }

  getInsightCSV(obj) {
    return this.insightRepo.getInsightCSV(obj)
  }
}
