import { DEFAULT_MSG } from '@/common/constant'

export enum MSG_TYPE {
  SEND = 0,
  RECEIVE = 1
}

export enum QUESTION_TYPE {
  TAG = 'TAG',
  LAYER = 'LAYER'
}

export class Message {
  msgType: MSG_TYPE
  questionType: string
  tags: string[]
  list: any[]
  description: string
  url: string
  showAll: boolean
  title: string
  id: string
  contact: string
  displayHome: boolean
  displayFinish: boolean

  constructor(
    msgType,
    typeQuestion,
    list?: any[],
    tags?: string[],
    title?: string,
    description?: string,
    url?: string,
    id?: string,
    contact?: string,
    displayHome?: boolean,
    displayFinish?: boolean
  ) {
    this.msgType = msgType
    this.questionType = typeQuestion
    this.list = list || []
    this.tags = tags || []
    this.description = description || ''
    this.url = url || ''
    this.showAll = !(list && list.length > 10)
    this.title = title || ''
    this.id = id || ''
    this.contact = contact || ''
    this.displayHome = displayHome || false
    this.displayFinish = displayFinish || false
  }
}
